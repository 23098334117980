<template>
  <div>
      <div v-if="store.sellerGroups" class="projects_list">
        <h2>Hier könnt ihr Shirts kaufen</h2>
        <p class="sellers_subline">Bleibt immer auf dem neusten Stand, auf welchen Festivals, Flohmärkten und Co. wir so unterwegs sind. Wenn ihr Shirtspenden habt, könnt ihr die an dem Tag auch gerne mitbringen und abgeben</p>
        <div class="project project_single" v-for="project in orderedEntries" :key="project.id">        
        <!-- header -->
        <div class="project__header_content">
          <h4>{{store.formatDate(project.organisationDatum)}}</h4>
          <h2>{{project.organisationName}}</h2>
          <a class="map_link" v-bind:href="'http://maps.google.com/?q='+project.organisationStrasse+' '+project.organisationPlz+' '+project.organisationStadt" target="_blank">
          <img class="marker_icon" alt="marker" src="@/assets/images/icons/map_icon.png">
          <div class="marker_adress">
          <span v-if="project.organisationStrasse != null">{{project.organisationStrasse}}, </span>
          <span v-if="project.organisationPlz != null">{{project.organisationPlz}}, </span>
          <span v-if="project.organisationStadt != null">{{project.organisationStadt}}</span>
          </div>
          </a>
          <img v-if="project.organisationFlyer[0]" class="city_image" alt="flyer" v-bind:src="project.organisationFlyer[0].url">
        </div>
        <a class="next-link" v-if="project.organisationUrl != null" v-bind:href="project.organisationUrl" target="_blank">Mehr Infos</a>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store/store.js';

export default {
  name: 'Sellers',
  components: {
  },
  data() {
    return {
      store,
    };
  },
  methods: {
    getContentData: function (dataHandle) {
      var contentModel = null;
      for(var i = 0; i < this.store.contentData.length; i++){
        if(this.store.contentData[i].pageHandle == dataHandle){
          contentModel = this.store.contentData[i];
        }
      }
      return contentModel;
    }
  },
  computed: {
  orderedEntries: function () {
    store.sellerGroups.sort((a, b) => {
      if ( a.organisationDatum < b.organisationDatum ){
        return -1;
      }
      if ( a.organisationDatum > b.organisationDatum ){
        return 1;
      }
      return 0;
    });

    var dateNow = new Date();
    var removeItemsAfterIndex = -1; 

    
    for(var i = 0; i < this.store.sellerGroups.length; i++){
        var d = new Date(this.store.sellerGroups[i].organisationDatum);
        d.setDate(d.getDate() + 1);

        if(d.getTime() <= dateNow.getTime() - (24 * 60 * 60)){
          removeItemsAfterIndex = i;
        }
      }

      var duplicatedArray = store.sellerGroups;
      if(removeItemsAfterIndex != -1){
        duplicatedArray.splice(0, removeItemsAfterIndex + 1);
      }

    return duplicatedArray;
  }
}
}
</script>

<style lang="scss">
</style>
